
import { defineComponent, computed } from 'vue'

import AsBannerContainer from '@web/components/AsBannerContainer.vue'
import AsBanner from '@web/components/AsBanner.vue'

export default defineComponent({
  name: 'AsBannersTop',
  props: {
    banners: {
      type: Object as () => Banner[],
      required: true
    },
    position: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      required: true
    },
    platform: {
      type: String,
      required: true
    },
    inDay: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AsBannerContainer,
    AsBanner
  },
  setup (props) {
    const filteredBanners = computed(() => {
      const banners = props.banners
        .filter(banner => {
          if (banner.placements.includes('sidebar')) {
            return banner.image.small
          }

          return banner.image.large
        })
      if (banners.length > 2) {
        banners.length = 2
      }
      return banners
    })
    const bannersCount = computed(() => filteredBanners.value.length)

    return {
      filteredBanners,
      bannersCount
    }
  }
})
