import urlParse from 'url-parse'
import Token from '@web/common/token'
import md5 from 'locutus/php/strings/md5'
import { analyticsBannerClick, analyticsBannerShow } from '@web/services/Analytics/events'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import useGetImageWithSize from '@web/composition/useGetImageWithSize'

export default function useBanner ({ banner, position, width }: { banner: Banner, position: string, width?: number }) {
  const refBanner = ref<HTMLElement | null>(null)
  const isShown = ref(false)

  /**
   * Получние utm меток для баннера
   *
   * @param { position: string, bannerID: string, query: Record<string, string | undefined> } payload
   * @param {string} payload.position - точное размещение баннера в момент показа
   * @param {string} payload.bannerID - ID баннера
   * @param {Record<string, string | undefined>} payload.query - query
   * @returns {object} Сгенерированные query параметры с utm метками
   */
  function getQuery ({ position, bannerID, query }: { position: string, bannerID: string, query: Record<string, string | undefined> }): Record<string, string> {
    const utmMarks: Record<string, string> = {
      utm_source: query.utm_source || 'lk',
      utm_medium: query.utm_medium || 'banner',
      utm_campaign: query.utm_campaign || position,
      utm_term: query.utm_term || bannerID
    }

    return {
      ...query,
      ...utmMarks,
      token: Token.get(),
      _uah: md5(window.navigator.userAgent)
    }
  }

  function getUrl (_banner?: Banner): string {
    const b = _banner || banner
    const url = b?.surveyFormUrl || b?.url
    if (url) {
      const urlInstance = urlParse(url, true)
      const query = getQuery({
        position,
        bannerID: b.id.toString(),
        query: urlInstance.query
      })
      urlInstance.set('query', query)
      return urlInstance.toString()
    }

    return ''
  }
  function getImage (_banner?: Banner): string {
    const b = _banner || banner
    if (width) {
      return useGetImageWithSize({ image: b?.image, width: width * 1.5 })
    }

    if (b?.placements.includes('sidebar')) {
      return b?.image.small
    }

    return b?.image.medium || b?.image.small
  }

  function onBannerShow () {
    if (refBanner.value && banner) {
      const screenVisiblePosition: number = window.scrollY + window.innerHeight
      const centerBanner = refBanner.value.offsetTop + (refBanner.value.offsetHeight / 2)
      if (screenVisiblePosition > centerBanner && !isShown.value) {
        analyticsBannerShow({ banner, position, url: getUrl() })
        isShown.value = true
      }
    }
  }

  function onBannerClick () {
    if (banner) {
      analyticsBannerClick({ banner, position, url: getUrl() })
    }
  }

  onMounted(() => {
    window.addEventListener('scroll', onBannerShow, true)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onBannerShow, true)
  })

  return {
    refBanner,
    isShown,
    url: getUrl(),
    getUrl,
    image: getImage(),
    getImage,
    onBannerShow,
    onBannerClick
  }
}
